/**
 * Enum of available environments.
 */
export enum AvailableEnvironments {
    Develop = 'develop',
    Test = 'test',
    Staging = 'staging',
    Production = 'production',
    Canary = 'canary',
}

export const environment = {
    env: process.env.NEXT_PUBLIC_ENV,
    availableEnvs: AvailableEnvironments,
    gitRevision: process.env.NEXT_PUBLIC_GIT_REVISION,
    api: {
        hostname: process.env.NEXT_PUBLIC_API_HOSTNAME,
    },
    mock: process.env.NEXT_PUBLIC_API_MOCKING,
    tenant: process.env.NEXT_PUBLIC_TENANT,
};

export const TENANT = {
    OTSUKA: 'otsuka',
    RICOH: 'ricoh',
};
