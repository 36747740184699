import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import * as React from "react";
import { globalActions, globalSelector } from "@state/globalState"

const Action: React.ReactNode = () => {
  return <div>info</div>;
};

export const Infobar = () => {
  const setSnackbar = globalActions.useSetSnackbarStatus()
  const snackbarStatus = globalSelector.useSnackbarStatus()

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({...snackbarStatus, open: false})
  };

  switch (snackbarStatus.variant) {
    case "error":
      return (
        <Snackbar
          open={snackbarStatus.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            variant="filled"
            severity="error"
            sx={{
              width: "100%",
              backgroundColor: "#b22a00",
            }}
          >
            {snackbarStatus.message}
          </Alert>
        </Snackbar>
      );
    case "success":
      return (
        <Snackbar
          open={snackbarStatus.open}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
          message="Success"
          action={Action}
        />
      );
  }
};
