import {
  V1CompanyCodeCreateRequest,
  V1CompanyCodeCreateResponse,
  V1ErrorsErrorResponse,
  V1CompanyCodeShowResponse,
} from "@schema/typescript/schema";
import { environment } from "@utils/constants/environment";
import { apiErrorHandler } from "@utils/apiErrorHandler";
import { axiosFactory } from "@utils/axiosFactory";

export const requestAuth = async (
  companyCode: string
): Promise<V1CompanyCodeCreateResponse | V1ErrorsErrorResponse> => {
  const body: V1CompanyCodeCreateRequest = {
    code: companyCode,
  };

  try {
    const response = await axiosFactory.post<V1CompanyCodeCreateResponse>(
      `https://${environment.api.hostname}/v1/code`,
      body
    );
    return response.data;
  } catch (e) {
    return apiErrorHandler(e);
  }
};

export const authCheck = async (
  auth: string
): Promise<V1CompanyCodeShowResponse | V1ErrorsErrorResponse> => {
  try {
    const response = await axiosFactory.get<V1CompanyCodeShowResponse>(
      `https://${environment.api.hostname}/v1/code`,
      {
        headers: { Authorization: `Bearer ${auth}` },
      }
    );
    return response.data;
  } catch (e) {
    return apiErrorHandler(e);
  }
};
