import { createTheme } from '@mui/material/styles';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';

interface ArtPlette extends SimplePaletteColorOptions {
    veryLight?: string;
}

declare module '@mui/material/styles/createPalette' {
    interface Palette {
        accent: ArtPlette;
        accent2: ArtPlette;
        success: PaletteColor;
        bodyText: ArtPlette;
        third: Palette['primary'];
    }
    interface PaletteOptions {
        accent?: ArtPlette;
        accent2?: ArtPlette;
        success?: PaletteColorOptions;
        bodyText?: ArtPlette;
        third?: PaletteOptions['primary'];
    }
    export interface PaletteColor {
        veryLight: string;
    }
    interface SimplePaletteColorOptions {
        veryLight?: string;
    }
}

let theme = createTheme({
    palette: {
        common: {
            black: '#333',
        },
        primary: {
            dark: '#325465',
            main: '#607E8C',
            light: '#B4C5CE',
            veryLight: '#E4EBEE',
        },
        secondary: {
            dark: '#FF5A00',
            main: '#FF7300',
            light: '#FFC440',
            veryLight: '#FFDF87',
        },
        third: {
            dark: '#990026',
            main: '#CC0033',
            light: '#FF3366',
            veryLight: '#FF99AA',
        },
        accent: {
            dark: '#CA0A22',
            main: '#C91227',
            light: '#FFA3B0',
            veryLight: '#FFEAED',
        },
        accent2: {
            dark: '#005A6C',
            main: '#01A5C4',
            light: '#68CFE3',
            veryLight: '#EAF8FA',
        },
        error: {
            main: '#ff3d00',
        },
        success: {
            main: '#43a047',
        },
        bodyText: {
            main: '#333',
        },
        text: {
            primary: '#333',
        },
    },
});

theme = createTheme({
    palette: theme.palette,
    typography: {
        fontSize: 15,
        fontFamily: ['Noto Sans JP', 'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo', 'sans-serif'].join(','),
        h1: {
            // 36px
            color: theme.palette.text.primary,
            fontSize: '2.25rem',
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                // 24px
                fontSize: '1.5rem',
            },
        },
        h2: {
            color: theme.palette.text.primary,
            // 26px
            fontSize: '1.625rem',
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                // 20px
                fontSize: '1.25rem',
            },
        },
        h3: {
            color: theme.palette.text.primary,
            // 20px
            fontSize: '1.25rem',
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                // 16px
                fontSize: '1rem',
            },
        },
        h4: {
            color: theme.palette.text.primary,
            // 16px
            fontSize: '1rem',
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                // 14px
                fontSize: '0.875rem',
            },
        },
        subtitle1: {
            color: theme.palette.text.primary,
            // 20px
            fontSize: '1.25rem',
            [theme.breakpoints.down('md')]: {
                // 18px
                fontSize: '1.125rem',
            },
        },
        subtitle2: {
            color: theme.palette.text.primary,
            // 20px
            fontSize: '1.25rem',
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
                // 18px
                fontSize: '1.125rem',
            },
        },

        body1: {
            color: theme.palette.text.primary,
            // 15px
            fontSize: '0.9375rem',
        },

        body2: {
            color: theme.palette.text.primary,
            // 14px
            fontSize: '0.875rem',
        },
        caption: {
            color: theme.palette.text.primary,
            // 12px
            fontSize: '0.75rem',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    margin: 5,
                    transition: '0.4s',
                },
                text: {
                    color: theme.palette.secondary.main,
                    fontWeight: 600,
                },
                contained: {
                    backgroundColor: theme.palette.secondary.main,
                    boxShadow: 'none',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.dark,
                    },
                    '&:active': {
                        backgroundColor: theme.palette.secondary.light,
                    },
                    '&$disabled': {
                        backgroundColor: theme.palette.grey[400],
                        color: '#fff',
                    },
                },
                fullWidth: {
                    borderRadius: 20,
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    backgroundColor: theme.palette.secondary.main,
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#ff5a00 !important',
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.grey[200],
                },
                underline: {
                    '&::before': {
                        borderColor: theme.palette.grey[400],
                    },
                    '&::after': {
                        borderColor: theme.palette.accent2.light,
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[600],
                    focused: {
                        color: theme.palette.accent2.light,
                    },
                },
            },
        },
    },
});

export { theme };
