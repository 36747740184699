export default {
    "action": {
        "apply": "action.apply",
        "authenticate": "action.authenticate",
        "back": "action.back"
    },
    "appBar": {
        "cancel": "appBar.cancel",
        "index": "appBar.index"
    },
    "completion": {
        "applicationNumber": "completion.applicationNumber",
        "explanation1": "completion.explanation1",
        "explanation2": "completion.explanation2",
        "explanation3": "completion.explanation3",
        "nextButton": "completion.nextButton",
        "noMailReason1": "completion.noMailReason1",
        "noMailReason2": "completion.noMailReason2",
        "noMailReason3": "completion.noMailReason3"
    },
    "debug": {
        "hello": "debug.hello",
        "mixed": "debug.mixed",
        "plural": {
            "one": "debug.plural.one",
            "two": "debug.plural.two",
            "index": "debug.plural"
        }
    },
    "entry": {
        "basic": {
            "category": {
                "index": "entry.basic.category.index",
                "new": "entry.basic.category.new",
                "trial": "entry.basic.category.trial"
            },
            "cloudService": {
                "box": "entry.basic.cloudService.box",
                "dropbox": "entry.basic.cloudService.dropbox",
                "explanation": "entry.basic.cloudService.explanation",
                "googleDrive": "entry.basic.cloudService.googleDrive",
                "googleSharedrive": "entry.basic.cloudService.googleSharedrive",
                "index": "entry.basic.cloudService.index",
                "m365OneDrive": "entry.basic.cloudService.m365OneDrive",
                "m365SharePoint": "entry.basic.cloudService.m365SharePoint",
                "noSelect": "entry.basic.cloudService.noSelect"
            },
            "index": "entry.basic.index",
            "productInformation": {
                "ef": "entry.basic.productInformation.ef",
                "explanation": "entry.basic.productInformation.explanation",
                "index": "entry.basic.productInformation.index",
                "noSelect": "entry.basic.productInformation.noSelect",
                "qs": "entry.basic.productInformation.qs"
            }
        },
        "client": {
            "companyName": {
                "index": "entry.client.companyName.index"
            },
            "departmentName": {
                "index": "entry.client.departmentName.index"
            },
            "index": "entry.client.index",
            "mail": {
                "attention1": "entry.client.mail.attention1",
                "attention2": "entry.client.mail.attention2",
                "attention3": "entry.client.mail.attention3",
                "index": "entry.client.mail.index",
                "placeholder": "entry.client.mail.placeholder"
            },
            "mailConfirm": {
                "attention1": "entry.client.mailConfirm.attention1",
                "attention2": "entry.client.mailConfirm.attention2",
                "index": "entry.client.mailConfirm.index",
                "placeholder": "entry.client.mailConfirm.placeholder"
            },
            "phone": {
                "attention": "entry.client.phone.attention",
                "index": "entry.client.phone.index",
                "placeholder": "entry.client.phone.placeholder"
            },
            "pic": {
                "firstName": {
                    "index": "entry.client.pic.firstName.index"
                },
                "index": "entry.client.pic.index",
                "lastName": {
                    "index": "entry.client.pic.lastName.index"
                }
            }
        },
        "description": {
            "consultationCounter": "entry.description.consultationCounter",
            "index": "entry.description.index",
            "sentence1": "entry.description.sentence1",
            "sentence2": "entry.description.sentence2",
            "sentence3": "entry.description.sentence3",
            "sentence4": "entry.description.sentence4",
            "sentence5": "entry.description.sentence5",
            "sentence6": "entry.description.sentence6",
            "sentence7": "entry.description.sentence7",
            "subtitle1": "entry.description.subtitle1",
            "subtitle2": "entry.description.subtitle2",
            "subtitle3": "entry.description.subtitle3",
            "url": "entry.description.url"
        },
        "engineer": {
            "index": "entry.engineer.index",
            "mail": {
                "attention": "entry.engineer.mail.attention",
                "index": "entry.engineer.mail.index",
                "placeholder": "entry.engineer.mail.placeholder"
            },
            "mailConfirm": {
                "attention1": "entry.engineer.mailConfirm.attention1",
                "attention2": "entry.engineer.mailConfirm.attention2",
                "index": "entry.engineer.mailConfirm.index",
                "placeholder": "entry.engineer.mailConfirm.placeholder"
            },
            "pic": {
                "firstName": {
                    "index": "entry.engineer.pic.firstName.index"
                },
                "index": "entry.engineer.pic.index",
                "lastName": {
                    "index": "entry.engineer.pic.lastName.index"
                }
            }
        },
        "parts": {
            "any": "entry.parts.any",
            "required": "entry.parts.required"
        },
        "sales": {
            "departmentName": {
                "index": "entry.sales.departmentName.index"
            },
            "index": "entry.sales.index",
            "mail": {
                "attention": "entry.sales.mail.attention",
                "index": "entry.sales.mail.index",
                "placeholder": "entry.sales.mail.placeholder"
            },
            "mailConfirm": {
                "attention1": "entry.sales.mailConfirm.attention1",
                "attention2": "entry.sales.mailConfirm.attention2",
                "index": "entry.sales.mailConfirm.index",
                "placeholder": "entry.sales.mailConfirm.placeholder"
            },
            "phone": {
                "attention": "entry.sales.phone.attention",
                "index": "entry.sales.phone.index",
                "placeholder": "entry.sales.phone.placeholder"
            },
            "pic": {
                "firstName": {
                    "index": "entry.sales.pic.firstName.index"
                },
                "index": "entry.sales.pic.index",
                "lastName": {
                    "index": "entry.sales.pic.lastName.index"
                }
            }
        }
    },
    "error": {
        "invalidFormat": "error.invalidFormat",
        "invalidToken": "error.invalidToken",
        "serverError": "error.serverError",
        "unknown": "error.unknown"
    },
    "index": {
        "agree": "index.agree",
        "explanation1Confirm": "index.explanation1Confirm",
        "explanation1Input": "index.explanation1Input",
        "explanation2": "index.explanation2",
        "explanation3_1": "index.explanation3_1",
        "explanation3_2": "index.explanation3_2",
        "explanation3_3": "index.explanation3_3",
        "explanation3_4": "index.explanation3_4",
        "explanation4": "index.explanation4",
        "explanation5": "index.explanation5",
        "explanation6": "index.explanation6",
        "explanationLink": "index.explanationLink",
        "snackbar": "index.snackbar",
        "snackbar2": "index.snackbar2"
    },
    "loading": {
        "applyLoading": "loading.applyLoading"
    },
    "otsukaCorporationAuth": {
        "input": {
            "attention": "otsukaCorporationAuth.input.attention",
            "code": "otsukaCorporationAuth.input.code",
            "instruct": "otsukaCorporationAuth.input.instruct"
        }
    },
    "pageTitle": {
        "root": "pageTitle.root"
    },
    "validation": {
        "companyName": "validation.companyName",
        "departmentName": "validation.departmentName",
        "email": "validation.email",
        "firstName": "validation.firstName",
        "inputLimit": "validation.inputLimit",
        "lastName": "validation.lastName",
        "phone": "validation.phone",
        "required": "validation.required",
        "tooLong": "validation.tooLong"
    }
}