// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { environment } from "./utils/constants/environment"

const SENTRY_DSN = process.env.WEBAPP_ENTRY_SENTRY_DSN || process.env.NEXT_PUBLIC_WEBAPP_ENTRY_SENTRY_DSN;

Sentry.init({
  dsn: ['develop', 'test'].includes(environment.env) ? undefined : SENTRY_DSN,
  debug: ['develop', 'test'].includes(environment.env) ? true : false,
  release: environment.gitRevision,
  environment: environment.env,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
