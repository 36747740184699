import LinearProgress from '@mui/material/LinearProgress';
import { globalSelector } from "@state/globalState"

export const Loading = () => {
    const isLoading = globalSelector.useLoading()
    return (
        <>
            {isLoading && <LinearProgress sx={{position: "fixed", width: "100vw", top: 0, left: 0}} color="secondary" />}
        </>
    )
}