import locale from "@utils/locale";
import { ErrorCode } from "@utils/constants/errorCode";
import Cookie from "js-cookie";
import axios, { AxiosError } from "axios";
import { V1ErrorsErrorResponse } from "@schema/typescript/schema";

// APIで起こりえるエラーハンドラー
// 401および403の認証エラーを返す。それ以外は不明なエラーとして処理（今後こまかく制御する可能性あり）。
export const apiErrorHandler = (e: any) => {
  // axiosでハンドリング可能なエラーの場合
  if (axios.isAxiosError(e)) {
    const axiosError = e as AxiosError<V1ErrorsErrorResponse>;

    // 意図しないレスポンスが返却されたら不明なエラーを表示
    if (axiosError.response === undefined) {
      // 想定できないエラー(時間をおいてリトライしてもらうように伝える)
      return {
        error: {
          code: ErrorCode.InternalError,
          debugMessage:
            "Internal server error (Probably caused by validation error)",
          message: locale.t(locale.keys.error.serverError),
          requestId: "entry-input",
          resource: "entry-input",
        },
        error_summary: "不明なエラー (Probably caused by validation error)",
      };
    }

    // HTTPステータスエラーかつ認証系エラーの場合は強制ログアウト
    switch (axiosError.response.status) {
      // password reset中などでauth失敗
      case 401:
        // 認証エラー系はクッキーを削除してloginにリダイレクト
        Cookie.remove("companycode");
        return {
          error: {
            code: ErrorCode.InvalidToken,
            debugMessage: "認証トークンが不正です。",
            message: locale.t(locale.keys.error.invalidToken),
            requestId: "entry-input",
            resource: "entry-input",
          },
          error_summary: "認証エラー",
        };
      // auth header 不一致
      case 403:
        // 認証エラー系はクッキーを削除してloginにリダイレクト
        Cookie.remove("companycode");
        return {
          error: {
            code: ErrorCode.InvalidToken,
            debugMessage: "認証トークンが不正です。",
            message: locale.t(locale.keys.error.invalidToken),
            requestId: "entry-input",
            resource: "entry-input",
          },
          error_summary: "認証エラー",
        };
    }
  }

  // 想定できないエラー(時間をおいてリトライしてもらうように伝える)
  return {
    error: {
      code: ErrorCode.InternalError,
      debugMessage:
        "Internal server error (Probably caused by validation error)",
      message: locale.t(locale.keys.error.serverError),
      requestId: "entry-input",
      resource: "entry-input",
    },
    error_summary: "不明なエラー (Probably caused by validation error)",
  };
};
