import { atom, useRecoilCallback, selector, useRecoilValue } from 'recoil'

export const keys = {
    GLOBAL_LOADING: {
        atom: "GlobalLoading_A",
        selector: "GlobalLoading_S"
    },
    GLOBAL_SNACKBAR: {
        atom: "GlobalSnackbar_A",
        selector: "GlobalSnackbar_S"
    },
    GLOBAL_TOKEN: {
        atom: "GlobalToken_A",
        selector: "GlobalToken_S"
    }
}

// state
const isLoading = atom({
    key: keys.GLOBAL_LOADING.atom,
    default: false
})

type SnackbarStatus = {
    open: boolean;
    variant: "error" | "success";
    message: string;
}

const initialSnackbarStatus: SnackbarStatus = {
    open: false,
    variant: "error",
    message: ""
}

const snackbarStatus = atom({
    key: keys.GLOBAL_SNACKBAR.atom,
    default: initialSnackbarStatus
})

type TokenState = {
    token: string | null,
    isLoading: boolean,
}

// isLoadingの初期値は、画面のちらつきを無くすためにtrueでなければならない。
// useEffectがコンポーネントのマウント後に動作するので、初期値がどうしても反映されてしまうからである。
const initialTokenState: TokenState = {
    token: null,
    isLoading: true
}

const heldToken = atom({
    key: keys.GLOBAL_TOKEN.atom,
    default: initialTokenState
})

// actions
type GlobalActions = {
    useSetLoading: () => (isLoading: boolean) => void,
    useSetSnackbarStatus: () => (snackbarStatus: SnackbarStatus) => void,
    useSetToken: () => (token: TokenState) => void
}

export const globalActions: GlobalActions = {
    useSetLoading: () =>
        useRecoilCallback(({ set }) => (loading: boolean) => {
            set(isLoading, () => {
                return loading
            })
        }),

    useSetSnackbarStatus: () =>
        useRecoilCallback(({ set }) => (status: SnackbarStatus) => {
            set(snackbarStatus, () => {
                return {...status}
            })
        }),

    useSetToken: () =>
        useRecoilCallback(({ set }) => (token: TokenState) => {
            set(heldToken, () => {
                return {...token}
            })
        })
}

// selector
type GlobalSelectors = {
    useLoading: () => boolean,
    useSnackbarStatus: () => SnackbarStatus,
    useToken: () => TokenState
}

const useGlobalLoadinSelector = selector({
    key: keys.GLOBAL_LOADING.selector,
    get: ({ get }) => get(isLoading)
})

const useGlobalSnackbarStatusSelector = selector({
    key: keys.GLOBAL_SNACKBAR.selector,
    get: ({ get }) => get(snackbarStatus)
})

const useGlobalHeldTokenSelector = selector({
    key: keys.GLOBAL_TOKEN.selector,
    get: ({ get }) => get(heldToken)
})

export const globalSelector: GlobalSelectors = {
    useLoading: () => useRecoilValue(useGlobalLoadinSelector),
    useSnackbarStatus: () => useRecoilValue(useGlobalSnackbarStatusSelector),
    useToken: () => useRecoilValue(useGlobalHeldTokenSelector)
}