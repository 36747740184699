import { rest } from "msw"
import { V1CompanyCodeCreateResponse, V1CompanyCodeCreateRequest, V1ErrorsErrorResponse, V1CompanyCodeShowResponse } from "@schema/typescript/schema"
import { environment } from "@utils/constants/environment"

export const codeHandler = [
    rest.post(`https://${environment.api.hostname}/v1/code`, (req, res, ctx) => {
        const body = req.body as V1CompanyCodeCreateRequest
        if(body.code === "test"){
            return res(
                ctx.status(201),
                ctx.json({
                    authorization: "mockcode"
                } as V1CompanyCodeCreateResponse)
            )
        }else{
            return res(
                ctx.status(201),
                ctx.json({
                    error: {
                        code: "",
                        debugMessage: "debug test",
                        message: "Bad Request",
                        requestId: "",
                        resource: "",
                    },
                    error_summary: "test_error"
                } as V1ErrorsErrorResponse)
            )
        }
    }),
    rest.get(`https://${environment.api.hostname}/v1/code`, (req, res, ctx) => {
        let heldToken = req.headers.get("authorization")
        if(heldToken === "Bearer mockcode") {
            return res(
                ctx.status(201),
                ctx.json({
                    authorized: true
                } as V1CompanyCodeShowResponse)
            )
        }else if(heldToken) {
            return res(
                ctx.status(201),
                ctx.json({
                    authorized: false
                } as V1CompanyCodeShowResponse)
            )
        }else {
            return res(
                ctx.status(201),
                ctx.json({
                    error: {
                        code: "",
                        debugMessage: "debug test",
                        message: "Bad Request",
                        requestId: "",
                        resource: "",
                    },
                    error_summary: "test_error"
                } as V1ErrorsErrorResponse)
            )
        }
    })
]