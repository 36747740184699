import type { AppProps } from 'next/app';
import { RecoilRoot } from 'recoil';
import { GlobalStyles, ThemeProvider } from '@mui/material';
import { theme } from '../globalStyle/globalStyle';
import { Loading } from '@components/parts/Loading';
import { Infobar } from '@components/parts/Snackbar';
import { globalActions } from "@state/globalState"
import { authCheck } from "@models/company-code"
import Cookie from "js-cookie"
import { useEffect } from "react"
import * as logger from "@utils/logger"
import useTitle from "@utils/hooks/useTitle"
import { pageTitleList } from "@utils/constants/pageTitle"

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
    require('../mock')
}

const InitApp = () => {
    const setError = globalActions.useSetSnackbarStatus()
    const setToken = globalActions.useSetToken()
    const setLoading = globalActions.useSetLoading()
    useTitle(pageTitleList.root)

    useEffect(() => {
        (async () => {
            try {
                logger.debug("Initialize useEffect")
                const heldToken = Cookie.get("companycode")
                if(heldToken) {
                    setToken({token: null, isLoading: true})
                    // ローディングステータス表示
                    setLoading(true)
                    const token = await authCheck(heldToken)
                    setLoading(false)
                    if("error" in token) {
                        // 認証エラーは無いはずなので、このエラーが発生した時点でバグ（500エラー系）
                        throw new Error(token.error.message)
                    }
                    setToken({token: heldToken, isLoading: false})
                    logger.debug("Initialize done")
                    return
                }
                logger.debug("Initialize done")
                setToken({token: null, isLoading: false})
                return
            } catch(e: unknown) {
                if(e instanceof Error){
                    setError({
                        open: true,
                        variant: "error",
                        message: e.message
                    })
                }
                setToken({token: null, isLoading: false})
                setLoading(false)
            }
        })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}

function MyApp({ Component, pageProps }: AppProps) {
    logger.debug("Init App")
    return (
        <>
            <RecoilRoot>
                <ThemeProvider theme={theme}>
                    <InitApp />
                    <GlobalStyles styles={{ body: { margin: 0, padding: 0 } }} />
                    <Loading />
                    <Component {...pageProps} />
                    <Infobar />
                </ThemeProvider>
            </RecoilRoot>
        </>
    );
}

export default MyApp;
