import { rest } from "msw"
import { V1EntryCreateResponse } from "@schema/typescript/schema"
import { environment } from "@utils/constants/environment"
import locale from '@utils/locale'
import { ErrorCode } from "@utils/constants/errorCode"

export const entryHandler = [
    rest.post(`https://${environment.api.hostname}/v1/entry`, (req, res, ctx) => {
        let heldToken = req.headers.get("authorization")
        if(heldToken === "Bearer mockcode") {
            return res(
                ctx.status(201),
                ctx.json({
                    entryNumber: "mockEntryNumber"
                } as V1EntryCreateResponse)
            )
        }else {
            return res(
                ctx.status(403),
                ctx.json({
                    error: {
                        code: ErrorCode.InvalidToken,
                        debugMessage: "認証トークンが不正です。",
                        message: locale.t(locale.keys.error.invalidToken),
                        requestId: "entry-input",
                        resource: "entry-input",
                    },
                    error_summary: "認証エラー",
                })
            )
        }
    })
]